import React, { useState } from "react";

const AuthContext = React.createContext({
  token: "",
  isLoggedIn: false,
  data: {},
  login: (dados) => {},
  logout: () => {},
});

export const AuthContextProvider = (props) => {
  const [token, setToken] = useState(
    localStorage.getItem("bbwpauth_token") || ""
  );
  const [tokenExpire, setTokenExpire] = useState(
    localStorage.getItem("bbwpauth_token_expire") || null
  );
  const [Data, setData] = useState(
    JSON.parse(localStorage.getItem("bbwpauth_data")) || {}
  );

  let userIsLoggedIn = !!token;

  if (tokenExpire === null) {
    localStorage.removeItem("bbwpauth_token");
    localStorage.removeItem("bbwpauth_data");
    localStorage.removeItem("bbwpauth_token_expire");
    userIsLoggedIn = false;
  } else {
    // ver se expirou
    if (new Date().getTime() > new Date(tokenExpire * 1000).getTime()) {
      console.log("token expired ... ");
      localStorage.removeItem("bbwpauth_token");
      localStorage.removeItem("bbwpauth_data");
      localStorage.removeItem("bbwpauth_token_expire");
      userIsLoggedIn = false;
    }
  }

  // obter token anteriormente guardado ..
  if (userIsLoggedIn) {
  }

  const loginHandler = (dados) => {
    setToken(dados.token);
    setData(dados.data);
    setTokenExpire(dados.tokenExpire);
    localStorage.setItem("bbwpauth_token", dados.token);
    localStorage.setItem("bbwpauth_token_expire", dados.tokenExpire);
    localStorage.setItem("bbwpauth_data", JSON.stringify(dados.data));
  };

  const logoutHandler = () => {
    setToken(null);
    setData({});
    setTokenExpire(null);
    localStorage.removeItem("bbwpauth_token");
    localStorage.removeItem("bbwpauth_token_expire");
    localStorage.removeItem("bbwpauth_data");
  };

  const contextValue = {
    token: token,
    isLoggedIn: userIsLoggedIn,
    login: loginHandler,
    logout: logoutHandler,
    data: Data,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
