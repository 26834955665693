import * as React from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import NotificationsIcon from "@mui/icons-material/Notifications";

import Copyright from "./Copyright";

import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PeopleIcon from "@mui/icons-material/People";
import BarChartIcon from "@mui/icons-material/BarChart";
import LayersIcon from "@mui/icons-material/Layers";
import AssignmentIcon from "@mui/icons-material/Assignment";

import EuroSymbolIcon from "@mui/icons-material/EuroSymbol";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";

import { useNavigate } from "react-router-dom";
import AuthContext from "./auth/auth-context";
import { useContext, useEffect } from "react";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import LogoutIcon from "@mui/icons-material/Logout";

import BBWP_API from "./api/bbwp-api";
import { Backdrop, CircularProgress } from "@mui/material";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const mdTheme = createTheme();

export const DashboardSubPage = (props) => {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);

  const [accountMenuAnchorEl, accountMenuSetAnchorEl] = React.useState(null);
  const accountMenuOpen = Boolean(accountMenuAnchorEl);
  const accountMenuHandleClick = (event) => {
    accountMenuSetAnchorEl(event.currentTarget);
  };
  const accountMenuHandleClose = () => {
    accountMenuSetAnchorEl(null);
  };
  const accountMenuHandleLogout = () => {
    // fazer logout na api
    BBWP_API.operation({
      action: "logout",
    }).then((dados) => {
      if (dados.success) {
        authCtx.logout();
        accountMenuSetAnchorEl(null);
        authCtx.isLoggedIn = false;
        if (typeof extendLogin != "undefined") {
          clearInterval(extendLogin);
        }
      }
    });
  };
  const [showLoading, setShowLoading] = React.useState(authCtx.isLoggedIn);
  /*
  if (authCtx.isLoggedIn) {
    BBWP_API.operation({
      action: "check_login",
    }).then((dados) => {
      if (dados.message !== "") {
        // alert(dados.message);
      }
      if (dados.success) {
        // tudo ok
        setShowLoading(false);
      } else {
        // erros...?
        // alert(dados.message);
        // logout ?
        authCtx.logout();
        authCtx.isLoggedIn = false;
        if (typeof extendLogin != "undefined") {
          clearInterval(extendLogin);
        }
      }
    });
  } else {
    console.log("sem login ");
  }
  */

  const [keepAlive, setKeepAlive] = React.useState(false);
  useEffect(() => {
    // criar beacon keep alive da sessao -- se fechar o browser a sessao vai ao ar ...
    if (keepAlive) {
      return;
    }

    if (authCtx.isLoggedIn && showLoading === true) {
      // tudo ok
      setShowLoading(false);
    }
    setKeepAlive(true);
    const extendLogin = setInterval(() => {
      if (!authCtx.isLoggedIn) {
        if (typeof extendLogin != "undefined") {
          clearInterval(extendLogin);
        }
        return;
      }
      BBWP_API.operation({
        action: "extend_login",
      }).then((dados) => {
        if (dados.success) {
          // tudo ok ...
        } else {
          // algo falhou
          // terminar sessao ...
          alert(dados.message);
          authCtx.logout();
          authCtx.isLoggedIn = false;
          if (typeof extendLogin != "undefined") {
            clearInterval(extendLogin);
          }
        }
      });
    }, 20000); // a cada x segundos ...
  }, [authCtx.isLoggedIn]);

  return (
    <ThemeProvider theme={mdTheme}>
      <Backdrop
        sx={{
          align: "center",
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={showLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="absolute">
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
              backgroundColor: "black",
            }}
          >
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Autenticação
            </Typography>

            <div>
              <Button
                sx={{
                  color: "common.white",
                  backgroundColor: "secondary",
                }}
                id="basic-button"
                aria-controls="basic-menu"
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={accountMenuHandleClick}
              >
                {authCtx.data.name}
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={accountMenuAnchorEl}
                open={accountMenuOpen}
                onClose={accountMenuHandleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem>{authCtx.data.name}</MenuItem>
                <Divider />
                <MenuItem onClick={accountMenuHandleLogout}>
                  <ListItemIcon>
                    <LogoutIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Terminar Sessão</ListItemText>
                </MenuItem>
              </Menu>
            </div>
            {/*
            <IconButton color="inherit">
              <Badge badgeContent={1} color="info">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            */}
          </Toolbar>
        </AppBar>

        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                  {props.children}
                </Paper>
              </Grid>
            </Grid>
            <Divider sx={{ marginTop: "50px" }} />
            <Copyright sx={{ pt: 4, marginBottom: "250px" }} />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
};
