import * as React from "react";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

import { useNavigate } from "react-router-dom";
import AuthContext from "./auth/auth-context";
import { useContext, useEffect } from "react";
import BBWP_API from "./api/bbwp-api";

import Button from "@mui/material/Button";
import { Container, Paper } from "@mui/material";

import PropTypes from "prop-types";

import { Box, Backdrop, CircularProgress, TextField } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";

function CircularProgressWithLabel(props) {
  const authCtx = useContext(AuthContext);
  const [code, setCode] = React.useState(authCtx.data.current_code_value);
  const [time, setTime] = React.useState(authCtx.data.current_code_timer);
  const [color, setColor] = React.useState("primary");
  const [color2, setColor2] = React.useState("text.secondary");
  const [colorCode, setColorCode] = React.useState("");

  const duracaoCodigo = 59; // em segundos / dur max

  React.useEffect(() => {
    const timer = setInterval(() => {
      if (authCtx.data.current_code_value !== "      ") {
        authCtx.data.current_code_timer =
          authCtx.data.current_code_timer <= 0
            ? 0
            : authCtx.data.current_code_timer - 100 / duracaoCodigo;
        authCtx.data.current_code_timer =
          authCtx.data.current_code_timer <= 0
            ? 0
            : authCtx.data.current_code_timer;
      }
      setTime(authCtx.data.current_code_timer);

      // reset ?
      if (
        authCtx.data.current_code_value_prev !==
          authCtx.data.current_code_value &&
        authCtx.data.current_code_value !== "      "
      ) {
        // reset timer e tal
        authCtx.data.current_code_value_prev = authCtx.data.current_code_value;
        // authCtx.data.current_code_timer = 100;
        setTime(100);
      }
      let cor = "primary";
      let progress = Math.round(authCtx.data.current_code_timer);
      if (progress < (100 / duracaoCodigo) * 19) {
        cor = "warning";
      }
      if (progress < (100 / duracaoCodigo) * 11) {
        cor = "error";
      }
      setColor(cor);

      if (cor !== "primary") {
        setColorCode(cor);
      }

      if (
        authCtx.data.current_code_timer <= 1 &&
        authCtx.data.current_code_value !== "      "
      ) {
        authCtx.data.current_code_value = "      ";
        setCode(authCtx.data.current_code_value);
        setColor("primary");
        authCtx.data.current_code_value_prev = authCtx.data.current_code_value;
        authCtx.data.current_code_timer = 100;
        setTime(100);
      }
      setCode(authCtx.data.current_code_value);
      // visibilidade
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  const [codigoCopiado, setCodigoCopiado] = React.useState(false);

  const copyCodeToClipboard = () => {
    navigator.clipboard.writeText(authCtx.data.current_code_value);
    // mostrar tooltip ??
    setCodigoCopiado(true);
    setTimeout(() => {
      setCodigoCopiado(false);
    }, 5000);
  };

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={2}
      sx={{ width: "230px", margin: "10px auto" }}
    >
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress
          variant="determinate"
          size="40px"
          thickness={4}
          color={color}
          value={time}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="caption" component="div" color={color2}>
            {`${Math.round((time / 100) * 60)}s`}
          </Typography>
        </Box>
      </Box>

      <Typography
        noWrap
        variant="h4"
        gutterBottom
        component="div"
        color={colorCode}
        sx={{ height: "40px", marginTop: "20px", marginBottom: "20px" }}
      >
        {code}
      </Typography>

      <Tooltip
        PopperProps={{
          disablePortal: true,
        }}
        open={codigoCopiado}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title="Código Copiado"
      >
        <IconButton
          color="primary"
          aria-label="Copiar Código"
          onClick={copyCodeToClipboard}
        >
          <ContentCopyIcon />
        </IconButton>
      </Tooltip>
    </Stack>
  );
}

function SubPageInicio(props) {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const [codigoVisivel, setCodigoVisivel] = React.useState(false);
  const getNewCode = () => {
    setCodigoVisivel(true);
    BBWP_API.operation({
      action: "get_current_code",
    }).then((dados) => {
      if (dados.success) {
        // tudo ok ...
        let tempoRestantePercentagem = parseInt(
          (parseInt(dados.timer) / 60) * 100
        );
        authCtx.data.current_code_value = dados.message;
        authCtx.data.current_code_timer = tempoRestantePercentagem;
        setTimeout(() => {
          setCodigoVisivel(false);
        }, (parseInt(dados.timer) - 1) * 1000);
      } else {
        // algo falhou -- terminar sessao ...
        alert(dados.message);
        authCtx.logout();
      }
    });
  };

  const [keepAlive, setKeepAlive] = React.useState(false);
  useEffect(() => {
    // criar beacon keep alive da sessao -- se fechar o browser a sessao vai ao ar ...
    if (keepAlive) {
      return;
    }
    setKeepAlive(true);
    authCtx.data.current_code_value_prev = "      ";
    authCtx.data.current_code_value = "      ";
    authCtx.data.current_code_timer = 100;
  }, [authCtx.isLoggedIn]);

  return (
    <Container>
      <br />
      <Box sx={{ textAlign: "center" }}>
        <Button
          variant="contained"
          size="medium"
          onClick={getNewCode}
          disabled={codigoVisivel}
        >
          Ver Código
        </Button>
        <br />
        <br />
        {codigoVisivel && <CircularProgressWithLabel />}
      </Box>
    </Container>
  );
}
export default SubPageInicio;
