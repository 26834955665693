import React, { useContext } from "react";

const bbwpauth_API = {

  api_url: "https://auth.bbwp.pt/authapi/authapi.php",

  login: async (properties = {}) => {
    const response = await fetch(bbwpauth_API.api_url, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(properties),
    });
    return response.json();
  },

  operation: async (properties = {}) => {
    const token = localStorage.getItem("bbwpauth_token") || "";
    const response = await fetch(bbwpauth_API.api_url, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(properties),
    });
    return response.json();
  },
};

export default bbwpauth_API;
